import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
const LoginScreen = lazy(() => import('../components/Login/LoginScreen'));
const LoginAdminScreen = lazy(() => import('../components/Login/LoginAdminScreen'));
const FullLayout = lazy(() => import('../layouts/FullLayout'));
const Progress = lazy(() => import('../components/Progress/Progress'));
const ProgressBuddy = lazy(() => import('../components/Progress/ProgressBuddy'));
const Home = lazy(() => import('../components/Home/HomeScreen'));
const Results = lazy(() => import('../components/Results/Results'));
const JoinUs = lazy(() => import('../components/JoinUs/JoinUs'));
const LogActivity = lazy(() => import('../components/LogActivity/LogActivity'));
const LogStudentsActivity = lazy(() => import('../components/LogActivity/LogStudentsActivity'));
const Videos = lazy(() => import('../components/Videos/Videos'));
const VideoVisualizer = lazy(() => import('../components/Videos/VideoVisualizer'));
const VideoCreation = lazy(() => import('../components/Videos/VideoCreation'));
const VideoEdit = lazy(() => import('../components/Videos/VideoEdit'));
const Profile = lazy(() => import('../components/Student/StudentProfile/Profile'));
const ProfileAdmin = lazy(() => import('../components/Admin/AdminProfile/Profile'));
const ProfileSchoolLeader = lazy(() => import('../components/SchoolLeader/SchoolLeaderProfile/Profile'));
const ProfileBuddy = lazy(() => import('../components/Buddy/BuddyProfile/Profile'));
const Events = lazy(() => import('../components/Events/Events'));
const EventDetails = lazy(() => import('../components/Events/EventDetails/EventDetails'));
const EventCreation = lazy(() => import('../components/Events/CreateEvent/CreateEvent'));
const EditEvent = lazy(() => import('../components/Events/EditEvent/EditEvent'));
const Schools = lazy(() => import('../components/Schools/Schools'));
const SchoolDetails = lazy(() => import('../components/Schools/SchoolDetails/SchoolDetails'));
const SchoolRegistration = lazy(() => import('../components/Schools/SchoolRegistration/SchoolRegistration'));
const SchoolLeaderSignUp = lazy(() => import('../components/SchoolLeader/SchoolLeaderSignUp/SchoolLeaderSignUp'));
const SchoolTeacherSignUp = lazy(() => import('../components/SchoolTeacher/SchoolTeacherSignUp/SchoolTeacherSignUp'));
const EntryDetails = lazy(() => import('../components/Schools/SchoolDetails/Entries/EntryDetails'));
const UserDetails = lazy(() => import('../components/Schools/SchoolDetails/Users/UserDetails'));
const Requests = lazy(() => import('../components/Requests/Requests'));
const AdminSignUp = lazy(() => import('../components/Admin/AdminSignUp/AdminSignUp'));
const Chat = lazy(() => import('../components/Chat/Chat'));
const BuddySignUp = lazy(() => import('../components/Buddy/BuddySignUp/BuddySignUp'));
const InviteBuddies = lazy(() => import('../components/InviteBuddies/InviteBuddies'));
const AthleteRegistration = lazy(() => import('../components/Athlete/AthleteRegistration/AthleteRegistration'));
const ResetPassword = lazy(() => import('../components/ResetPassword/ResetPassword'));
const ForgotPassword = lazy(() => import('../components/ForgotPassword/ForgotPassword'));
const BuddyEmailForm = lazy(() => import('../components/InviteBuddies/BuddyEmailForm'));
const CommonRoutes = [
    { path: 'results', name: 'Results', element: _jsx(Results, {}) },
    { path: 'videos', name: 'Videos', element: _jsx(Videos, {}) },
    { path: 'videos/:videoID', name: 'Video Visualizer', element: _jsx(VideoVisualizer, {}) },
];
const RegistrationRoutes = [
    { path: 'sign-up/admin', name: 'Admin Registration', element: _jsx(AdminSignUp, {}) },
    { path: 'sign-up/leader', name: 'School Leader Registration', element: _jsx(SchoolLeaderSignUp, {}) },
    { path: 'sign-up/teacher', name: 'School Teacher Registration', element: _jsx(SchoolTeacherSignUp, {}) },
    { path: 'sign-up/buddy', name: 'Buddy Registration', element: _jsx(BuddySignUp, {}) },
    { path: 'buddy/invitation', name: 'Buddy Invitation', element: _jsx(BuddyEmailForm, {}) },
    { path: 'school/registration', name: 'School Registration', element: _jsx(SchoolRegistration, {}) },
    { path: 'sign-up/athlete', name: 'Athete Registration', element: _jsx(AthleteRegistration, {}) },
];
export const ThemeRoutes = [
    { path: '', name: 'Home', element: _jsx(Navigate, { to: '/home' }) },
    {
        path: '/',
        element: _jsx(FullLayout, {}),
        children: [
            { path: '404', element: _jsx(_Fragment, {}) },
            { path: 'home', name: 'Home', element: _jsx(Home, {}) },
            { path: 'results', name: 'Results', element: _jsx(Results, {}) },
            { path: 'joinus', name: 'Join Us', exact: true, element: _jsx(JoinUs, {}) },
            { path: '*', name: 'Home', element: _jsx(Navigate, { to: '/home' }) },
        ],
    },
    {
        path: '/login/students',
        element: _jsx(LoginScreen, {}),
    },
    { path: '/forgot-password', name: 'Forgot Password', exact: true, element: _jsx(ForgotPassword, {}) },
    {
        path: '/login',
        element: _jsx(LoginAdminScreen, {}),
    },
    ...RegistrationRoutes,
];
export const AuthorizedAdminRoutes = [
    { path: '*', name: 'Universal', element: _jsx(Navigate, { to: '/admin/events' }) },
    {
        path: '/admin/',
        element: _jsx(FullLayout, {}),
        children: [
            { path: '404', element: _jsx(_Fragment, {}) },
            { path: '*', name: 'Home', element: _jsx(Navigate, { to: 'events' }) },
            { path: 'events', name: 'Events', element: _jsx(Events, {}) },
            { path: 'event/:eventID', name: 'Event Details', element: _jsx(EventDetails, {}) },
            { path: 'event/new', name: 'Event Creation', element: _jsx(EventCreation, {}) },
            { path: 'event/edit', name: 'Edit Event', element: _jsx(EditEvent, {}) },
            { path: 'schools', name: 'Schools', element: _jsx(Schools, {}) },
            { path: 'school/:schoolID', name: 'School Details', element: _jsx(SchoolDetails, {}) },
            { path: 'requests', name: 'Requests', element: _jsx(Requests, {}) },
            { path: 'profile', name: 'Profile', element: _jsx(ProfileAdmin, {}) },
            { path: 'chat', name: 'Chat', element: _jsx(Chat, {}) },
            { path: 'entry/:entryID', name: 'Entry Details', element: _jsx(EntryDetails, {}) },
            { path: 'user/:userID', name: 'User Details', element: _jsx(UserDetails, {}) },
            { path: 'videos/new', name: 'User Details', element: _jsx(VideoCreation, {}) },
            { path: 'videos/edit', name: 'User Details', element: _jsx(VideoEdit, {}) },
            { path: 'reset-password', name: 'Reset Password', element: _jsx(ResetPassword, {}) },
            ...CommonRoutes,
        ],
    },
];
export const AuthorizedSchoolLeaderRoutes = [
    { path: '*', name: 'Universal', element: _jsx(Navigate, { to: '/leader/event/progress' }) },
    {
        path: '/leader/',
        element: _jsx(FullLayout, {}),
        children: [
            { path: '404', element: _jsx(_Fragment, {}) },
            { path: '*', name: 'Home', element: _jsx(Navigate, { to: 'event/progress' }) },
            { path: 'event/progress', name: 'School Progress', element: _jsx(EventDetails, {}) },
            { path: 'event/new', name: 'Event Creation', element: _jsx(EventCreation, {}) },
            { path: 'event/edit', name: 'Edit Event', element: _jsx(EditEvent, {}) },
            { path: 'school', name: 'School Details', element: _jsx(SchoolDetails, {}) },
            { path: 'activity/new', name: 'Log Activity', element: _jsx(LogStudentsActivity, {}) },
            { path: 'profile', name: 'Profile', element: _jsx(ProfileSchoolLeader, {}) },
            { path: 'chat', name: 'Chat', element: _jsx(Chat, {}) },
            { path: 'entry/:entryID', name: 'Entry Details', element: _jsx(EntryDetails, {}) },
            { path: 'user/:userID', name: 'User Details', element: _jsx(UserDetails, {}) },
            { path: 'reset-password', name: 'Reset Password', element: _jsx(ResetPassword, {}) },
            ...CommonRoutes,
        ],
    },
];
export const AuthorizedSchoolTeacherRoutes = [
    { path: '*', name: 'Universal', element: _jsx(Navigate, { to: '/teacher/event/progress' }) },
    {
        path: '/teacher/',
        element: _jsx(FullLayout, {}),
        children: [
            { path: '404', element: _jsx(_Fragment, {}) },
            { path: '*', name: 'Home', element: _jsx(Navigate, { to: 'event/progress' }) },
            { path: 'event/progress', name: 'School Progress', element: _jsx(EventDetails, {}) },
            { path: 'school', name: 'School Details', element: _jsx(SchoolDetails, {}) },
            { path: 'activity/new', name: 'Log Activity', element: _jsx(LogActivity, {}) },
            { path: 'profile', name: 'Profile', element: _jsx(ProfileSchoolLeader, {}) },
            { path: 'entry/:entryID', name: 'Entry Details', element: _jsx(EntryDetails, {}) },
            { path: 'user/:userID', name: 'User Details', element: _jsx(UserDetails, {}) },
            { path: 'reset-password', name: 'Reset Password', element: _jsx(ResetPassword, {}) },
            ...CommonRoutes,
        ],
    },
];
export const AuthorizedStudentRoutes = [
    { path: '*', name: 'Universal', element: _jsx(Navigate, { to: '/student/progress' }) },
    {
        path: '/student/',
        element: _jsx(FullLayout, {}),
        children: [
            { path: '404', element: _jsx(_Fragment, {}) },
            { path: '*', name: 'Home', element: _jsx(Navigate, { to: 'progress' }) },
            { path: 'progress', name: 'Progress', element: _jsx(Progress, {}) },
            { path: 'activity/new', name: 'Log Activity', element: _jsx(LogActivity, {}) },
            { path: 'profile', name: 'Profile', element: _jsx(Profile, {}) },
            { path: 'invite', name: 'Invite Buddies', element: _jsx(InviteBuddies, {}) },
            ...CommonRoutes,
        ],
    },
];
export const AuthorizedBuddiesRoutes = [
    { path: '*', name: 'Universal', element: _jsx(Navigate, { to: '/buddy/progress' }) },
    {
        path: '/buddy/',
        element: _jsx(FullLayout, {}),
        children: [
            { path: '404', element: _jsx(_Fragment, {}) },
            { path: '*', name: 'Home', element: _jsx(Navigate, { to: 'progress' }) },
            { path: 'progress', name: 'Progress', element: _jsx(ProgressBuddy, {}) },
            { path: 'activity/new', name: 'Log Activity', element: _jsx(LogActivity, {}) },
            { path: 'profile', name: 'Profile', element: _jsx(ProfileBuddy, {}) },
            { path: 'reset-password', name: 'Reset Password', element: _jsx(ResetPassword, {}) },
            ...CommonRoutes,
        ],
    },
];
