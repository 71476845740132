var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLogin } from './useLogin';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROLES_ENUM } from '../constants/Roles';
import { resetUserPassword } from '../services/UserAPI/UserAPI';
import Swal from 'sweetalert2';
export const useAuth = () => {
    const { doLogin, doLogout } = useLogin();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const role = useSelector((state) => state.auth.role);
    const userID = useSelector((state) => state.auth.userID);
    const loading = useSelector((state) => state.auth.loading);
    const location = useLocation();
    const [isAdminLogin, setIsAdminLogin] = useState(false);
    const navigate = useNavigate();
    const getRoleName = useCallback(() => {
        let roleName;
        switch (role) {
            case ROLES_ENUM.ADMIN:
                roleName = 'admin';
                break;
            case ROLES_ENUM.LEADER:
                roleName = 'leader';
                break;
            case ROLES_ENUM.TEACHER:
                roleName = 'teacher';
                break;
            case ROLES_ENUM.STUDENT:
                roleName = 'student';
                break;
            case ROLES_ENUM.BUDDY:
                roleName = 'buddy';
                break;
            default:
                roleName = '';
                break;
        }
        return roleName;
    }, [role]);
    useEffect(() => {
        if (location.pathname === '/login')
            setIsAdminLogin(true);
    }, [location.pathname]);
    const resetPassword = useCallback((data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield resetUserPassword(data).then((resp) => {
                if (resp.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Password updated succesfully',
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(() => {
                        navigate(`/${getRoleName()}/profile`);
                    });
                }
            });
        }
        catch (err) { }
    }), [getRoleName, navigate]);
    return useMemo(() => ({
        isLoggedIn,
        role,
        userID,
        loading,
        doLogin,
        doLogout,
        isAdminLogin,
        getRoleName,
        resetPassword,
    }), [isLoggedIn, role, userID, loading, doLogin, doLogout, isAdminLogin, getRoleName, resetPassword]);
};
