var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useMemo } from 'react';
import { loginUser, logout } from '../store/auth/AuthSlice';
import { useAppDispatch } from './useAppDispatch';
export const useLogin = () => {
    const dispatch = useAppDispatch();
    const doLogin = useCallback(({ email, username, password }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield dispatch(loginUser({ email, username, password }));
        }
        catch (e) { }
    }), [dispatch]);
    const doLogout = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield dispatch(logout());
        }
        catch (e) { }
    }), [dispatch]);
    return useMemo(() => ({ doLogin, doLogout }), [doLogin, doLogout]);
};
