var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { registerAdmin } from '../../services/UserAPI/AdminAPI';
const initialState = {
    loading: false,
    error: false,
};
export const register = createAsyncThunk('/admin/register/leader', (data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield registerAdmin(data);
}));
export const AdminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        clear: () => initialState,
    },
    extraReducers(builder) {
        builder.addCase(register.pending, (state) => (Object.assign(Object.assign({}, state), { loading: true })));
        builder.addCase(register.fulfilled, (state, action) => {
            if (!!action.payload)
                return Object.assign(Object.assign({}, state), { loading: false });
        });
        builder.addCase(register.rejected, (state) => (Object.assign(Object.assign({}, state), { error: true })));
    },
});
export default AdminSlice.reducer;
