var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API, APINoAuthNeeded } from '../API';
const routeName = '/admin';
export const getAllRequests = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(routeName + '/school-requests');
});
export const approveRequest = (schoolID) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.post(`${routeName}/school-requests/${schoolID}/approve`);
});
export const rejectRequest = (schoolID) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.post(`${routeName}/school-requests/${schoolID}/approve`);
});
export const getApprovedSchools = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(routeName + '/schools');
});
export const getAllEvents = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(routeName + '/events');
});
export const inviteAdmin = (email) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.post(routeName + '/invite-admin', {
        email: email,
    });
});
export const registerAdmin = (adminRegistrationData) => __awaiter(void 0, void 0, void 0, function* () {
    return yield APINoAuthNeeded.post(routeName + '/register', {
        registration_token: adminRegistrationData.token,
        email: adminRegistrationData.email,
        password: adminRegistrationData.passwordConfirmation,
        screen_name: adminRegistrationData.screenName,
        first_name: adminRegistrationData.firstName,
        last_name: adminRegistrationData.lastName,
        age: adminRegistrationData.age,
        gender: adminRegistrationData.gender,
        zipcode: adminRegistrationData.zipCode,
        address: adminRegistrationData.address,
        city: adminRegistrationData.city,
        state: adminRegistrationData.state,
        invitee: adminRegistrationData.inviteID,
    });
});
export const createVideo = (videoData) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.post(routeName + '/video', {
        title: videoData.title,
        link: videoData.link,
        summary: videoData.summary,
    });
});
export const editVideo = (videoData) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.put(routeName + '/video/' + videoData.id, {
        title: videoData.title,
        link: videoData.link,
        summary: videoData.summary,
    });
});
export const deleteVideo = (videoID) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.delete(routeName + '/video/' + videoID);
});
