import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    userDataFile: [],
    uploadFileError: [],
    isUploadLoading: false,
    sports: [],
    forgotUserEmail: '',
};
export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserDataFile: (state, action) => {
            state.userDataFile = action.payload;
        },
        setUpladFileError: (state, action) => {
            state.uploadFileError = action.payload;
        },
        setIsUploadLoading: (state, action) => {
            state.isUploadLoading = action.payload;
        },
        setSports: (state, action) => {
            state.sports = action.payload;
        },
        setForgotUserEmail: (state, action) => {
            state.forgotUserEmail = action.payload;
        },
        clearSelection: (state) => {
            state.uploadFileError = [];
            state.userDataFile = [];
        },
        clear: () => initialState,
    },
    extraReducers() { },
});
export const { setUserDataFile, setUpladFileError, clearSelection, setIsUploadLoading, setSports, setForgotUserEmail } = UserSlice.actions;
export default UserSlice.reducer;
