import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    eventActive: 0,
};
export const PersistSlice = createSlice({
    name: 'persist',
    initialState,
    reducers: {
        setEventActive: (state, action) => {
            state.eventActive = action.payload;
        },
        clear: () => initialState,
    },
});
export const { setEventActive } = PersistSlice.actions;
export default PersistSlice.reducer;
