var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { login } from '../../services/AuthAPI/AuthAPI';
import { API } from '../../services/API';
import { setEventActive } from '../persist/PersistSlice';
import Swal from 'sweetalert2';
const initialState = {
    loading: false,
    isLoggedIn: false,
    role: 0,
    userID: 0,
};
export const loginUser = createAsyncThunk('/login', (data) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const resp = yield login(data);
        return resp;
    }
    catch (err) {
        const error = err;
        Swal.fire({
            icon: 'error',
            title: 'Hmm...',
            text: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error,
            showConfirmButton: false,
            timer: 5000,
        });
    }
}));
export const logout = createAsyncThunk('/logout', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    localStorage.removeItem('token');
    thunkAPI.dispatch(setEventActive(0));
    delete API.defaults.headers.common['Authorization'];
    return true;
}));
export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        clear: () => initialState,
    },
    extraReducers(builder) {
        builder.addCase(loginUser.pending, (state) => {
            return Object.assign(Object.assign({}, state), { loading: true });
        });
        builder.addCase(loginUser.fulfilled, (state, action) => {
            if (!!action.payload && !!action.payload.data) {
                localStorage.setItem('token', action.payload.data.token);
                API.defaults.headers.common['Authorization'] = `Bearer ${action.payload.data.token}`;
                return Object.assign(Object.assign({}, state), { role: action.payload.data.role, userID: action.payload.data.user_id, isLoggedIn: true, loading: false });
            }
        });
        builder.addCase(loginUser.rejected, (state) => {
            return Object.assign(Object.assign({}, state), { loading: false });
        });
        builder.addCase(logout.fulfilled, () => {
            return Object.assign({}, initialState);
        });
    },
});
export const { setIsLoggedIn } = AuthSlice.actions;
export default AuthSlice.reducer;
