import axios from 'axios';
const apiURL = process.env.API_URL;
export const reduxENV = process.env.REDUX_ENV;
export const API = axios.create({
    baseURL: apiURL,
    headers: {
        'Content-Type': 'application/json',
    },
});
API.interceptors.request.use((config) => {
    var _a, _b, _c, _d;
    const headerValue = (_d = (_c = (_b = (_a = config.headers) === null || _a === void 0 ? void 0 : _a.Authorization) === null || _b === void 0 ? void 0 : _b.toString()) === null || _c === void 0 ? void 0 : _c.split(' ')) === null || _d === void 0 ? void 0 : _d[1];
    if (!headerValue) {
        const interceptorToken = localStorage.getItem('token');
        config.headers.Authorization = `Bearer ${interceptorToken}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
export const APINoAuthNeeded = axios.create({
    baseURL: apiURL,
    headers: {
        'Content-Type': 'application/json',
    },
});
