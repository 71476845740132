var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import UserPhoto from '../../assets/images/user.png';
const initialState = {
    chats: [],
    chatContent: 1,
    chatSearch: '',
};
const ChatData = [
    {
        id: 1,
        name: 'James Johnson',
        status: 'online',
        thumb: UserPhoto,
        recent: false,
        excerpt: 'Theme Developer',
        chatHistory: [
            {
                0: {
                    from: ['Tom got a small piece of pie.'],
                    to: ["I'd rather be a bird than a fish.", 'They got there early, and they got really good seats.'],
                },
                1: {
                    from: ['If I don’t like something, I’ll stay away from it.', 'I want more detailed information.'],
                    to: ['We need to rent a room for our party.'],
                },
            },
        ],
    },
    {
        id: 2,
        name: 'Maria Hernandez',
        status: 'away',
        thumb: UserPhoto,
        recent: true,
        excerpt: 'Doctor',
        chatHistory: [
            {
                0: {
                    from: ['The sky is clear', 'How was the math test?'],
                    to: ['She always speaks to him in a loud voice.'],
                },
                1: {
                    from: [
                        'The memory we used to share is no longer coherent.',
                        'The mysterious diary records the voice.',
                        'The old apple revels in its authority.',
                    ],
                    to: ['Please wait outside of the house.'],
                },
            },
        ],
    },
    {
        id: 3,
        name: 'David Smith',
        status: 'busy',
        thumb: UserPhoto,
        recent: false,
        excerpt: 'Hacker',
        chatHistory: [
            {
                0: {
                    from: ['Tom got a small piece of pie.'],
                    to: ["I'd rather be a bird than a fish.", 'They got there early, and they got really good seats.'],
                },
                1: {
                    from: ['If I don’t like something, I’ll stay away from it.', 'I want more detailed information.'],
                    to: ['We need to rent a room for our party.'],
                },
            },
        ],
    },
    {
        id: 4,
        name: 'Maria Rodriguez',
        status: 'offline',
        thumb: UserPhoto,
        recent: true,
        excerpt: 'Accountant',
        chatHistory: [
            {
                0: {
                    from: ['The sky is clear', 'How was the math test?'],
                    to: ['She always speaks to him in a loud voice.'],
                },
                1: {
                    from: [
                        'The memory we used to share is no longer coherent.',
                        'The mysterious diary records the voice.',
                        'The old apple revels in its authority.',
                    ],
                    to: ['Please wait outside of the house.'],
                },
            },
        ],
    },
    {
        id: 5,
        name: 'Robert Smith',
        status: 'online',
        thumb: UserPhoto,
        recent: true,
        excerpt: 'Front End Developer',
        chatHistory: [
            {
                0: {
                    from: ['Tom got a small piece of pie.'],
                    to: ["I'd rather be a bird than a fish.", 'They got there early, and they got really good seats.'],
                },
                1: {
                    from: ['If I don’t like something, I’ll stay away from it.', 'I want more detailed information.'],
                    to: ['We need to rent a room for our party.'],
                },
            },
        ],
    },
    {
        id: 6,
        name: 'Joseph Sarah',
        status: 'busy',
        thumb: UserPhoto,
        recent: false,
        excerpt: 'Graphics Designer',
        chatHistory: [
            {
                0: {
                    from: ['The sky is clear', 'How was the math test?'],
                    to: ['She always speaks to him in a loud voice.'],
                },
                1: {
                    from: [
                        'The memory we used to share is no longer coherent.',
                        'The mysterious diary records the voice.',
                        'The old apple revels in its authority.',
                    ],
                    to: ['Please wait outside of the house.'],
                },
            },
        ],
    },
    {
        id: 7,
        name: 'Thomas Smith',
        status: 'away',
        thumb: UserPhoto,
        recent: true,
        excerpt: 'Back End Developer',
        chatHistory: [
            {
                0: {
                    from: ['Tom got a small piece of pie.'],
                    to: ["I'd rather be a bird than a fish.", 'They got there early, and they got really good seats.'],
                },
                1: {
                    from: ['If I don’t like something, I’ll stay away from it.', 'I want more detailed information.'],
                    to: ['We need to rent a room for our party.'],
                },
            },
        ],
    },
    {
        id: 8,
        name: 'David Elizabeth',
        status: 'offline',
        thumb: UserPhoto,
        recent: false,
        excerpt: 'Theme Developer',
        chatHistory: [
            {
                0: {
                    from: ['The sky is clear', 'How was the math test?'],
                    to: ['She always speaks to him in a loud voice.'],
                },
                1: {
                    from: [
                        'The memory we used to share is no longer coherent.',
                        'The mysterious diary records the voice.',
                        'The old apple revels in its authority.',
                    ],
                    to: ['Please wait outside of the house.'],
                },
            },
        ],
    },
    {
        id: 9,
        name: 'Charles Martha',
        status: 'online',
        thumb: UserPhoto,
        recent: false,
        excerpt: 'Administrator',
        chatHistory: [
            {
                0: {
                    from: ['Tom got a small piece of pie.'],
                    to: ["I'd rather be a bird than a fish.", 'They got there early, and they got really good seats.'],
                },
                1: {
                    from: ['If I don’t like something, I’ll stay away from it.', 'I want more detailed information.'],
                    to: ['We need to rent a room for our party.'],
                },
            },
        ],
    },
    {
        id: 10,
        name: 'Samuel Eliza',
        status: 'online',
        thumb: UserPhoto,
        recent: false,
        excerpt: 'Doctor',
        chatHistory: [
            {
                0: {
                    from: ['The sky is clear', 'How was the math test?'],
                    to: ['She always speaks to him in a loud voice.'],
                },
                1: {
                    from: [
                        'The memory we used to share is no longer coherent.',
                        'The mysterious diary records the voice.',
                        'The old apple revels in its authority.',
                    ],
                    to: ['Please wait outside of the house.'],
                },
            },
        ],
    },
];
export const ChatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        getChats: (state, action) => {
            state.chats = action.payload;
        },
        SearchChat: (state, action) => {
            state.chatSearch = action.payload;
        },
        SelectChat: (state, action) => {
            state.chatContent = action.payload;
        },
        sendMsg: (state, action) => {
            const { id, chatMsg } = action.payload;
            state.chats = state.chats.map((chat) => chat.id === id
                ? Object.assign(Object.assign({}, chat), { chatHistory: [
                        ...(chat.chatHistory || []),
                        [new Date(), chatMsg], // Assuming a new message is an array with timestamp and message
                    ] }) : chat);
        },
    },
});
export const { SearchChat, getChats, sendMsg, SelectChat } = ChatSlice.actions;
export const fetchChats = () => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = { data: ChatData };
        dispatch(getChats(response.data));
    }
    catch (err) { }
});
export default ChatSlice.reducer;
