import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useState } from 'react';
export const VideoAlertContext = createContext({});
export const VideoAlertContextProvider = ({ children }) => {
    const [showAlert, setShowAlert] = useState();
    const [tries, setTries] = useState(0);
    const context = useMemo(() => ({ showAlert, setShowAlert, tries, setTries }), [showAlert, setShowAlert]);
    return _jsx(VideoAlertContext.Provider, Object.assign({ value: context }, { children: children }));
};
export const useVideoAlertContext = () => useContext(VideoAlertContext);
