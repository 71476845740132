var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ROLES_ENUM } from '../../constants/Roles';
import { API } from '../API';
const routeName = '/user';
export const uploadStudentData = (userDataFile, schoolID, role) => {
    const formData = new FormData();
    formData.append('file', userDataFile);
    const query = routeName + '/upload-students' + (role === ROLES_ENUM.ADMIN ? `?schoolID=${schoolID}` : '');
    return API.post(query, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
export const getTemplateXLSX = () => {
    return API.get(routeName + '/template/download', { responseType: 'blob' });
};
export const resetUserPassword = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.post(routeName + '/reset-password', {
        old_password: data.currentPassword,
        new_password: data.newPassword,
        confirm_new_password: data.confirmNewPassword,
    });
});
export const sendEmailRecoverPassword = (email) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.post(routeName + '/send-otp', {
        email: email,
    });
});
export const recoverPassword = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.post(routeName + '/forgot-password', {
        email: data.email,
        otp: data.otp,
        password: data.password,
        confirm_password: data.passwordConfirmation,
    });
});
export const getSports = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(routeName + '/sports');
});
export const logActivity = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.post(routeName + '/log-activity', { sports: data });
});
export const getStudentProfileInfo = (userID, role) => __awaiter(void 0, void 0, void 0, function* () {
    const validRoles = role === ROLES_ENUM.ADMIN || role === ROLES_ENUM.LEADER || role === ROLES_ENUM.TEACHER;
    const query = routeName + '/profiles/student' + (validRoles ? `?userID=${userID}` : '');
    return yield API.get(query);
});
export const getStudentProfileEntries = (userID, role) => __awaiter(void 0, void 0, void 0, function* () {
    const validRoles = role === ROLES_ENUM.ADMIN || role === ROLES_ENUM.LEADER || role === ROLES_ENUM.TEACHER;
    const query = routeName + '/activities' + (validRoles ? `?userID=${userID}` : '');
    return yield API.get(query);
});
export const getAdminProfileInfo = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(routeName + '/profiles/admin');
});
export const getLeaderProfileInfo = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(routeName + '/profiles/school-leader');
});
export const getActivityInfo = (activityID) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(routeName + `/${activityID}/activity`);
});
export const deleteActivity = (activityID) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.delete(routeName + `/${activityID}/activity`);
});
export const approveActivity = (activityID) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.put(routeName + `/activities/${activityID}/approve`);
});
export const rejectActivity = (activityID) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.put(routeName + `/activities/${activityID}/reject`);
});
export const getVideos = (page, sortOrder, searchString) => __awaiter(void 0, void 0, void 0, function* () {
    let query = routeName + '/videos?page=' + page;
    if (!!sortOrder)
        query = query.concat('&sortOrder=' + sortOrder);
    if (!!searchString)
        query = query.concat('&search=' + searchString);
    return yield API.get(query);
});
export const getVideoDetails = (videoID) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(routeName + '/video/' + videoID);
});
export const getUserBadges = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(routeName + '/badges');
});
