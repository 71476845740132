import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
export const SidebarContext = createContext({});
export const SidebarContextProvider = ({ children }) => {
    const [showSideBar, setShowSideBar] = useState(false);
    const [showMobileSideBar, setShowMobileSideBar] = useState(false);
    const toggleSideBar = useCallback(() => {
        setShowSideBar(!showSideBar);
    }, [showSideBar]);
    const toggleMobileSideBar = useCallback(() => {
        setShowMobileSideBar(!showMobileSideBar);
    }, [showMobileSideBar]);
    const context = useMemo(() => ({ showSideBar, toggleSideBar, showMobileSideBar, toggleMobileSideBar }), [showSideBar, toggleSideBar, showMobileSideBar, toggleMobileSideBar]);
    return _jsx(SidebarContext.Provider, Object.assign({ value: context }, { children: children }));
};
export const useSidebarContext = () => useContext(SidebarContext);
