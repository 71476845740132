import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    details: null,
};
export const VideoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {
        setVideoDetails: (state, action) => {
            state.details = action.payload;
        },
        clear: () => initialState,
    },
    extraReducers() { },
});
export const { setVideoDetails } = VideoSlice.actions;
export default VideoSlice.reducer;
