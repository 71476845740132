import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './auth/AuthSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ChatsReducer from './chat/ChatSlice';
import UserReducer from './user/UserSlice';
import AdminReducer from './user/AdminSlice';
import SchoolReducer from './school/SchoolSlice';
import PersistReducer from './persist/PersistSlice';
import VideoReducer from './video/VideoSlice';
const authPersistConfig = {
    key: 'root',
    storage,
};
const persistPersistConfig = {
    key: 'persist',
    storage,
};
const persistedAuthReducer = persistReducer(authPersistConfig, AuthReducer);
const persistedPersistReducer = persistReducer(persistPersistConfig, PersistReducer);
export const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        chat: ChatsReducer,
        user: UserReducer,
        school: SchoolReducer,
        persist: persistedPersistReducer,
        admin: AdminReducer,
        video: VideoReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});
export default store;
export const persistor = persistStore(store);
