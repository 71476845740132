var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import dayjs from 'dayjs';
import { ROLES_ENUM } from '../../constants/Roles';
import { API, APINoAuthNeeded } from '../API';
import states from 'states-us';
const routeName = '/school';
export const registerSchool = (schoolRegistrationData) => __awaiter(void 0, void 0, void 0, function* () {
    const stateAbb = states.find((state) => state.name === schoolRegistrationData.state);
    return yield APINoAuthNeeded.post(routeName + '/register', {
        school_name: schoolRegistrationData.schoolName,
        address: schoolRegistrationData.address,
        city: schoolRegistrationData.city,
        state: schoolRegistrationData.state,
        state_abb: stateAbb === null || stateAbb === void 0 ? void 0 : stateAbb.abbreviation,
        country: schoolRegistrationData.country,
        representative_name: schoolRegistrationData.nameContactPerson,
        email: schoolRegistrationData.email,
        phone: schoolRegistrationData.phone,
        program_type: schoolRegistrationData.type,
        grade: schoolRegistrationData.grades,
    });
});
export const registerSchoolLeader = (leaderRegistrationData) => __awaiter(void 0, void 0, void 0, function* () {
    return yield APINoAuthNeeded.post(routeName + '/register/leader', {
        registration_token: leaderRegistrationData.token,
        email: leaderRegistrationData.email,
        password: leaderRegistrationData.passwordConfirmation,
        display_name: leaderRegistrationData.screenName,
        first_name: leaderRegistrationData.firstName,
        last_name: leaderRegistrationData.lastName,
        age: leaderRegistrationData.age,
        gender: leaderRegistrationData.gender,
        zip_code: leaderRegistrationData.zipCode,
        address: leaderRegistrationData.address,
        city: leaderRegistrationData.city,
        state: leaderRegistrationData.state,
        school_id: leaderRegistrationData.schoolID,
    });
});
export const getSchoolDetails = (schoolID) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(`${routeName}/${schoolID}`);
});
export const getUserSchoolDetails = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(routeName + '/user');
});
export const getGradesBySchoolID = (schoolID) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(`${routeName}/${schoolID}/grades`);
});
export const getGradesByUser = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(routeName + '/grades/user');
});
export const createSchoolEvent = (eventCreationData) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.post(routeName + '/events', {
        name: eventCreationData.name,
        start_date: eventCreationData.from,
        end_date: eventCreationData.to,
        grades: eventCreationData.grades,
        school_id: eventCreationData.schoolID,
    });
});
export const editSchoolEvent = (editEventData) => __awaiter(void 0, void 0, void 0, function* () {
    const fromDate = dayjs(editEventData.from);
    const toDate = dayjs(editEventData.to);
    return yield API.put(routeName + '/events/' + editEventData.id, {
        name: editEventData.name,
        start_date: fromDate.isValid() ? fromDate.toDate() : '',
        end_date: toDate.isValid() ? toDate.toDate() : '',
    });
});
export const getEventDetailsSchool = (eventID) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(`${routeName}/events/${eventID}`);
});
export const getEventDetailsResult = (eventID) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(`${routeName}/events/${eventID}/results`);
});
export const getStudentEventDetails = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(routeName + '/events/student');
});
export const getBuddyEventDetails = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(routeName + '/events/buddy');
});
export const getEventResultDetails = (eventID, role) => __awaiter(void 0, void 0, void 0, function* () {
    const query = routeName + '/events/user' + (role === ROLES_ENUM.ADMIN ? `?eventID=${eventID}` : '');
    return yield API.get(query);
});
export const getEventParticipants = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get(routeName + '/events/participants');
});
